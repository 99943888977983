<template>
  <div class="enigma-container">
    <overlay-navigation class="absolute w-full h-full" :hide-info="true" />
  </div>
</template>

<script>
import OverlayNavigation from '@/components/OverlayNavigation.vue'
export default {
  name: 'Enigma4',
  components: {
    OverlayNavigation
  },
  mounted() {
    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
  }
}
</script>

<style lang="scss" scoped>
  .enigma-container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: url('../../assets/bg/enigma-reflect-min.jpg') center center;
    background-size: contain;
    background-repeat: repeat-x;
    @media screen and (min-width: 768px) {
      background: url('../../assets/bg/enigma-reflect.jpg') center center;
      background-size: contain;
      background-repeat: repeat-x;
    }
  }
</style>